<template>
  <div class="home__container">
    <div class="mask__container" ref="mask">
      <div class="home__swiper-container">
        <swiper class="home-swiper__wrapper" :options="swiperOptions" ref="homeSwiper">
          <swiper-slide class="home-slider__item" v-for="(scroll, index) in slides" :key="index">
            <a class="slide-inner">
              <div class="home-slider-item__img-box parallax-container">
                <img :src="scroll.src" alt="" />
              </div>
            </a>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination hidden" ref="pagination"></div>
        <div v-if="$bowser.mobile" class="home__mobile-info-container">
          <div class="current__title hidden" ref="title">
            <p></p>
          </div>
          <div class="current__name hidden" ref="name">
            <p>{{ currentTitle }} | {{ currentName }}</p>
          </div>
        </div>
        <template v-else>
          <div class="current__title hidden" ref="title">
            <p>{{ currentTitle }}</p>
          </div>
          <div class="current__name hidden" ref="name">
            <p>{{ currentName }}</p>
          </div>
        </template>
      </div>
    </div>
    <div class="home__bg" ref="bg"></div>
    <div class="l4 home__ani-logo" ref="aniLogo" @animationend="onAnimationEnd"></div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import GSAP from 'gsap';
import events from '@/utils/eventBus';

const tl = GSAP.timeline();

export default {
  name: 'view-home',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    const vue = this;
    return {
      currentTitle: '',
      currentName: '',
      slides: [
        {
          src: require('@/assets/images/demo1/home/1.jpg'),
          type: 'Sales Center',
          name: '正荣青云宸院展示中心',
        },
        {
          src: require('@/assets/images/demo1/home/5.jpg'),
          type: 'Sales Center',
          name: '淄博万科翡翠东第地下会所',
        },
        {
          src: require('@/assets/images/demo1/home/4.jpg'),
          type: 'Residence',
          name: '正荣莆田函江白棠云麓样板间',
        },
        {
          src: require('@/assets/images/demo1/home/3.jpg'),
          type: 'Sales Center',
          name: '沈阳华润瑞府展示中心',
        },
        {
          src: require('@/assets/images/demo1/home/2.jpg'),
          type: 'Residence',
          name: '大发·碧桂园澜庭序样板间',
        },
        {
          src: require('@/assets/images/demo1/home/6.jpg'),
          type: 'Residence',
          name: '常州星河国际样板间',
        },
      ],
      swiperOptions: {
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
        effect: 'fade',
        loop: true,
        speed: 1000,
        mousewheelControl: true,
        keyboardControl: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'fraction',
          formatFractionCurrent(number) {
            return number < 10 ? `0${number}` : number;
          },
          formatFractionTotal(number) {
            return number < 10 ? `0${number}` : number;
          },
        },
        on: {
          init() {
            const { name, type } = vue.$data.slides[0];
            vue.currentName = name;
            vue.currentTitle = type;
          },
          transitionStart() {
            // vue.$refs.name.classList.add('beforeShow');
            GSAP.fromTo(
              vue.$refs.name.querySelector('p'),
              {
                y: '0px',
                opacity: 1,
              },
              {
                y: '-30px',
                opacity: 0,
                duration: 0.5,
              },
            );
            GSAP.fromTo(
              vue.$refs.title.querySelector('p'),
              {
                x: '0px',
                opacity: 1,
              },
              {
                x: '30px',
                opacity: 0,
                duration: 0.5,
              },
            );
          },
          transitionEnd() {
            const swiper = this;
            const { name, type } = vue.$data.slides[swiper.realIndex];
            vue.currentName = name;
            vue.currentTitle = type;

            if (!GSAP.isTweening(vue.$refs.name.querySelector('p')) && swiper.autoplay.running) {
              GSAP.fromTo(
                vue.$refs.name.querySelector('p'),
                {
                  y: '100px',
                  opacity: 0,
                },
                {
                  y: 0,
                  opacity: 1,
                  duration: 0.5,
                },
              );
            }

            if (!GSAP.isTweening(vue.$refs.title.querySelector('p')) && swiper.autoplay.running) {
              GSAP.fromTo(
                vue.$refs.title.querySelector('p'),
                {
                  x: '-30px',
                  opacity: 0,
                },
                {
                  x: 0,
                  opacity: 1,
                  duration: 0.5,
                },
              );
            }
          },
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.aniLogo.classList.add('start');
      this.homeAnimation();
      events.$emit('pageMounted');
    });
  },
  destroyed() {
    // document.querySelector('.header__container').classList.remove('show', 'hidden');
  },
  methods: {
    onAnimationEnd() {
      tl.play();
      this.$refs.homeSwiper.$swiper.autoplay.stop();
    },
    homeAnimation() {
      tl.pause();
      const { mask } = this.$refs;
      tl.to(
        mask,
        {
          duration: 1.5,
          ease: 'Expo.easeInOut',
          scale: '1',
        },
        '-=.5',
      );
      tl.eventCallback('onComplete', () => {
        if (document.querySelector('.header__container')) {
          document.querySelector('.header__container').classList.add('show');
        }
        this.$refs.pagination.classList.remove('hidden');
        this.$refs.title.classList.remove('hidden');
        if (!GSAP.isTweening(this.$refs.name.querySelector('p'))) {
          GSAP.set(this.$refs.name.querySelector('p'), {
            y: '100px',
            opacity: 0,
          });
          GSAP.to(this.$refs.name.querySelector('p'), {
            y: 0,
            opacity: 1,
            duration: 0.8,
            onComplete: () => {
              this.$refs.homeSwiper.$swiper.autoplay.start();
            },
          });
        }

        if (!GSAP.isTweening(this.$refs.title.querySelector('p'))) {
          GSAP.set(this.$refs.title.querySelector('p'), {
            y: '-30px',
            opacity: 0,
          });
          GSAP.to(this.$refs.title.querySelector('p'), {
            y: 0,
            opacity: 1,
            duration: 0.8,
            onComplete: () => {
              this.$refs.homeSwiper.$swiper.autoplay.start();
            },
          });
        }
        // this.$refs.homeSwiper.$swiper.autoplay.start();
      });
    },
  },
};
</script>

<style></style>
